import React, { forwardRef, useImperativeHandle, useEffect, useState, useRef } from 'react';
import TextBox from 'devextreme-react/text-box';
import { Button } from 'devextreme-react';

const TextBox2 =  forwardRef(({ onTbvalueChange, buttonStyle, placeHolderText, buttonText, textField, visible, value }, ref) => {
    const textBoxRef = useRef();
    const [tbValue, setTbValue] = useState(value);
    useEffect(() => {
        textBoxRef.current.instance.focus();
        setTbValue(value);
    },[value]);

    useImperativeHandle(
        ref,
        () => ({
            setFocus(e) {
                tb_setFocus(e);
            },
            clear(e) {
                tb_clear(e);
            },
        }),
    )

    function tb_onChangedHandler(event){
        setTbValue(event.value);
        onTbvalueChange(event.value);
    }

    function search_onClick(){
        console.log('click')
        onTbvalueChange(tbValue);
    }

    function tb_setFocus(e)
    {
        textBoxRef.current.instance.focus();
    }

    function tb_clear(e)
    {
        textBoxRef.current.instance.reset();
    }
    return (
        <div>
            <div className= {(textField == 'popup-text') ?  "dx-fieldset-popup" : "dx-fieldset" }>
                <div className="dx-field">
                    <div className="dx-field-value" >
                        <TextBox 
                            id="quicksearch"
                            className="mousetrap"
                            value={tbValue}
                            stylingMode={'outlined'}
                            placeholder={placeHolderText}
                            mode='search'
                            valueChangeEvent='change'
                            onValueChanged={tb_onChangedHandler}
                            visible = {visible}                            
                            ref={textBoxRef}>
                        </TextBox>

                        <Button
                            className="search-button"
                            onClick={search_onClick}
                            visible={visible}>

                                {buttonStyle === 'search-word' &&
                                <div className="search-text">{buttonText}</div>}

                                {buttonStyle === 'search-icon' && <div className="icon-search-icon"></div>}
                               
                        </Button>
                    </div>
                </div>
            </div>  
        </div>
    );
})

export default TextBox2;