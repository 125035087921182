import CustomStore from 'devextreme/data/custom_store';
import { DecimalPoints } from '../../../utils/default-cookies';

//Calculate footer total price
export const calculateFooterPrice = (packageItemList) => {

  var footerPrice = {
    totalPrice1: packageItemList.reduce((total, obj) => (obj.PI_SalesPrice1 * obj.PI_Qty) + total,0),
    totalPrice2: packageItemList.reduce((total, obj) => (obj.PI_SalesPrice2 * obj.PI_Qty) + total,0),      
    totalPrice3: packageItemList.reduce((total, obj) => (obj.PI_SalesPrice3 * obj.PI_Qty) + total,0),
    totalPrice4: packageItemList.reduce((total, obj) => (obj.PI_SalesPrice4 * obj.PI_Qty) + total,0),
    totalPrice5: packageItemList.reduce((total, obj) => (obj.PI_SalesPrice5 * obj.PI_Qty) + total,0),
    totalPrice6: packageItemList.reduce((total, obj) => (obj.PI_SalesPrice6 * obj.PI_Qty) + total,0),
    totalPurchasePrice: packageItemList.reduce((total, obj) => (obj.PI_PurchasePrice * obj.PI_Qty) + total,0)
  }

  return footerPrice;
}

//#region auto calculate item price function
export const computePackageItemPriceRatio = (packageItemPrice, packageItemList) =>
{

    if(packageItemPrice === undefined || packageItemList === undefined) return;
    var newPackageItemPrice = packageItemPrice;

    //get old package item price N = sum (sub item price N * sub item qty) 
    var oldPackageItemPrice = {
      totalPrice1: packageItemList.reduce((total, obj) => (obj.PI_SalesPrice1 * obj.PI_Qty) + total,0),
      totalPrice2: packageItemList.reduce((total, obj) => (obj.PI_SalesPrice2 * obj.PI_Qty) + total,0),      
      totalPrice3: packageItemList.reduce((total, obj) => (obj.PI_SalesPrice3 * obj.PI_Qty) + total,0),
      totalPrice4: packageItemList.reduce((total, obj) => (obj.PI_SalesPrice4 * obj.PI_Qty) + total,0),
      totalPrice5: packageItemList.reduce((total, obj) => (obj.PI_SalesPrice5 * obj.PI_Qty) + total,0),
      totalPrice6: packageItemList.reduce((total, obj) => (obj.PI_SalesPrice6 * obj.PI_Qty) + total,0),
      totalPurchasePrice: packageItemList.reduce((total, obj) => (obj.PI_PurchasePrice * obj.PI_Qty) + total,0)
    }

    //get item price ratio = new package item price / old package item price
    var itemPriceRatio = 
    {
      ratioPrice1: (newPackageItemPrice.totalPrice1 !== '' && newPackageItemPrice.totalPrice1 !== null) ? isNaN(newPackageItemPrice.totalPrice1/oldPackageItemPrice.totalPrice1) === true ? 0 : newPackageItemPrice.totalPrice1/oldPackageItemPrice.totalPrice1 : 1,            
      ratioPrice2: (newPackageItemPrice.totalPrice2 !== '' && newPackageItemPrice.totalPrice2 !== null) ? isNaN(newPackageItemPrice.totalPrice2/oldPackageItemPrice.totalPrice2) === true ? 0 : newPackageItemPrice.totalPrice2/oldPackageItemPrice.totalPrice2 : 1,
      ratioPrice3: (newPackageItemPrice.totalPrice3 !== '' && newPackageItemPrice.totalPrice3 !== null) ? isNaN(newPackageItemPrice.totalPrice3/oldPackageItemPrice.totalPrice3) === true ? 0 : newPackageItemPrice.totalPrice3/oldPackageItemPrice.totalPrice3 : 1,
      ratioPrice4: (newPackageItemPrice.totalPrice4 !== '' && newPackageItemPrice.totalPrice4 !== null) ? isNaN(newPackageItemPrice.totalPrice4/oldPackageItemPrice.totalPrice4) === true ? 0 : newPackageItemPrice.totalPrice4/oldPackageItemPrice.totalPrice4 : 1,
      ratioPrice5: (newPackageItemPrice.totalPrice5 !== '' && newPackageItemPrice.totalPrice5 !== null) ? isNaN(newPackageItemPrice.totalPrice5/oldPackageItemPrice.totalPrice5) === true ? 0 : newPackageItemPrice.totalPrice5/oldPackageItemPrice.totalPrice5 : 1,
      ratioPrice6: (newPackageItemPrice.totalPrice6 !== '' && newPackageItemPrice.totalPrice6 !== null) ? isNaN(newPackageItemPrice.totalPrice6/oldPackageItemPrice.totalPrice6) === true ? 0 : newPackageItemPrice.totalPrice6/oldPackageItemPrice.totalPrice6 : 1,
      ratioPurchasePrice: (newPackageItemPrice.totalPurchasePrice !== '' && newPackageItemPrice.totalPurchasePrice !== null) ? isNaN(newPackageItemPrice.totalPurchasePrice/oldPackageItemPrice.totalPurchasePrice) === true ? 0 : newPackageItemPrice.totalPurchasePrice/oldPackageItemPrice.totalPurchasePrice : 1,
    }
    return itemPriceRatio;
}

export const computePackageSubItemPrice = (packageItemList, packageItemPriceRatio) =>
{

  var newPackageItemList = new Array(0);
  packageItemList.forEach(element => {
    newPackageItemList.push({
      PI_ID: element.PI_ID,
      PI_ParentItemID: element.PI_ParentItemID,
      PI_ChildItemID: element.PI_ChildItemID,
      PI_ClientID: element.PI_ClientID,
      PI_Group: element.PI_Group,
      PI_IAGID: element.PI_IAGID,
      ChildItemCode: element.ChildItemCode,
      ChildItemDescription: element.ChildItemDescription,
      ChildItemCode_URL: element.ChildItemCode_URL,
      PI_Qty: element.PI_Qty,
      PI_SalesPrice1: parseFloat(((element.PI_Qty * element.PI_SalesPrice1 * packageItemPriceRatio.ratioPrice1)/element.PI_Qty).toFixed(DecimalPoints())),
      PI_SalesPrice2: parseFloat(((element.PI_Qty * element.PI_SalesPrice2 * packageItemPriceRatio.ratioPrice2)/element.PI_Qty).toFixed(DecimalPoints())),
      PI_SalesPrice3: parseFloat(((element.PI_Qty * element.PI_SalesPrice3 * packageItemPriceRatio.ratioPrice3)/element.PI_Qty).toFixed(DecimalPoints())),
      PI_SalesPrice4: parseFloat(((element.PI_Qty * element.PI_SalesPrice4 * packageItemPriceRatio.ratioPrice4)/element.PI_Qty).toFixed(DecimalPoints())),
      PI_SalesPrice5: parseFloat(((element.PI_Qty * element.PI_SalesPrice5 * packageItemPriceRatio.ratioPrice5)/element.PI_Qty).toFixed(DecimalPoints())),
      PI_SalesPrice6: parseFloat(((element.PI_Qty * element.PI_SalesPrice6 * packageItemPriceRatio.ratioPrice6)/element.PI_Qty).toFixed(DecimalPoints())),
      PI_PurchasePrice: parseFloat(((element.PI_Qty * element.PI_PurchasePrice * packageItemPriceRatio.ratioPurchasePrice)/element.PI_Qty).toFixed(DecimalPoints())),
      PI_ModifierNonRepeat_YN: element.PI_ModifierNonRepeat_YN,
      PI_Sequence:element.PI_Sequence
    });
  });
  return newPackageItemList;
}

export const getParentItemID = (parentItemId) => 
{
  var addParentItemId = { parentItemId: parentItemId }
  return addParentItemId;
}

export const computePackageItemTotalPrice = (packageItemList) =>
{

  //get old package item price N = sum (sub item price N * sub item qty) 
  var oldPackageItemPrice = {
    totalPrice1: packageItemList.reduce((total, obj) => (obj.PI_SalesPrice1 * obj.PI_Qty) + total,0),
    totalPrice2: packageItemList.reduce((total, obj) => (obj.PI_SalesPrice2 * obj.PI_Qty) + total,0),      
    totalPrice3: packageItemList.reduce((total, obj) => (obj.PI_SalesPrice3 * obj.PI_Qty) + total,0),
    totalPrice4: packageItemList.reduce((total, obj) => (obj.PI_SalesPrice4 * obj.PI_Qty) + total,0),
    totalPrice5: packageItemList.reduce((total, obj) => (obj.PI_SalesPrice5 * obj.PI_Qty) + total,0),
    totalPrice6: packageItemList.reduce((total, obj) => (obj.PI_SalesPrice6 * obj.PI_Qty) + total,0),
    totalPurchasePrice: packageItemList.reduce((total, obj) => (obj.PI_PurchasePrice * obj.PI_Qty) + total,0)
  }
  
  return oldPackageItemPrice;
}

export const changePackageSubItemPrice = (packageItemList, key, value, selectedRowID) => 
{
  for (var i in packageItemList) {
    if(packageItemList[i].PI_ID === selectedRowID && (packageItemList[i][key] - value) > 0){
      packageItemList[i][key] = packageItemList[i][key] - value;
      break; //Stop this loop, we found it!
    }
  }
}

export const updateItemUOMPrice = (itemUOMList, newItemPrice) => {

    itemUOMList[0].IU_SalesPrice1 = newItemPrice.totalPrice1;
    itemUOMList[0].IU_SalesPrice2 = newItemPrice.totalPrice2;
    itemUOMList[0].IU_SalesPrice3 = newItemPrice.totalPrice3;
    itemUOMList[0].IU_SalesPrice4 = newItemPrice.totalPrice4;
    itemUOMList[0].IU_SalesPrice5 = newItemPrice.totalPrice5;
    itemUOMList[0].IU_SalesPrice6 = newItemPrice.totalPrice6;
    itemUOMList[0].IU_MinSalesPrice = newItemPrice.totalPurchasePrice;

    return itemUOMList;
  
}
//#endregion

//#region API

export const GetAllCompaniesIAGByUser = (token, clientID, userID, isAdmin) =>
{
  const GETrequestOptions = {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization' : "Bearer " + token
    })
  }

  return fetch(`${process.env.REACT_APP_API_BASE_URL}api/Company/GetAllCompaniesIAGByUser?clientID=${clientID}&UserID=${userID}&IsAdmin=${isAdmin}&UI-Culture=en-US`, GETrequestOptions)
  .then(handleErrors)  
  .then(response => response.json())
  .then(data =>{
    return data;
  })
  .catch((error) => {console.log(error); return error;});  
}

export const GetPackageItemByCompanyID = (token, companyID, clientID, userID, isAdmin, searchKeyword, lstColName) =>
{
  const GETrequestOptions = {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization' : "Bearer " + token
    })
  }

  const dataSource_PackageItem = new CustomStore({
    key: 'IM_ID',
    load: function(loadOptions) {
      let params = '?';
      [
        'skip',
        'take',
        'requireTotalCount',
        'requireGroupCount',
        'sort',
        'filter',
        'totalSummary',
        'group',
        'groupSummary'
      ].forEach(function(i) {
        if (i in loadOptions && this.isNotEmpty(loadOptions[i]))
        { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
      });
      params = params.slice(0, -1);
      searchKeyword = encodeURIComponent(searchKeyword);
      return fetch(`${process.env.REACT_APP_API_PACKAGE_ITEM}companyID=${companyID}&clientID=${clientID}&UserID=${userID}&IsAdmin=${isAdmin}&UI-Culture=en-US&searchKeyword=${searchKeyword}&lstColName=${lstColName}`, GETrequestOptions)
        .then(handleErrors2)    
        .then(response => response.json())
        .then((data) => {
          return {
            data: data.Obj.Query_PackageItem_withCodeURL,
            totalCount: data.Obj.TotalPackageItem,
          };
        })
      
    },
  });
  return dataSource_PackageItem;
}

export const GetChildPackageItemByCompanyID = (token, companyID, parentItemID, iAGID) =>
{
  const GETrequestOptions = {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization' : "Bearer " + token
    })
  }

  return fetch(`${process.env.REACT_APP_API_PACKAGE_ITEM_DETAILS}companyID=${companyID}&parentItemID=${parentItemID}&iAGID=${iAGID}&UI-Culture=en-US`, GETrequestOptions)
  .then(handleErrors)
  .then(response => response.json())
  .then(data =>{
    return data.Obj;
  })
  .catch((error) => {console.log(error); return error;});  
}

export const GetChildPackageItemByCompanyID2 = (token, parentItemID, iAGID) =>
{
  const GETrequestOptions = {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization' : "Bearer " + token
    })
  }

  const dataSource_PackageItem = new CustomStore({
    key: 'PI_ID',
    load: function(loadOptions) {
      let params = '?';
      [
        'skip',
        'take',
        'requireTotalCount',
        'requireGroupCount',
        'sort',
        'filter',
        'totalSummary',
        'group',
        'groupSummary'
      ].forEach(function(i) {
        if (i in loadOptions && this.isNotEmpty(loadOptions[i]))
        { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
      });
      params = params.slice(0, -1);
      return fetch(`${process.env.REACT_APP_API_PACKAGE_ITEM_DETAILS}parentItemID=${parentItemID}&iAGID=${iAGID}&UI-Culture=en-US`, GETrequestOptions)
        .then(handleErrors2)
        .then(response => response.json())
        .then((data) => {
          console.log(data.Obj.Query_PackageChildItem);
          return {
            data: data.Obj.Query_PackageChildItem,
            totalCount: data.Obj.TotalChildPackageItem,
            addNewItemUrl: data.Obj.AddNewItem_URL,
            LinkBtnUrlAuditLog: data.Obj.LinkBtnUrlAuditLog
          };
        })
        .catch((error) => {console.log(error); return error;});  
    },
  });
  return dataSource_PackageItem;
}

export const GetAdvanceSearchItemByCompanyID = (token, companyID, clientID, userID, searchKeyword, lstColName) =>
{
  const GETrequestOptions = {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization' : "Bearer " + token
    })
  }
  searchKeyword = encodeURIComponent(searchKeyword);
  return fetch(`${process.env.REACT_APP_API_ADVANCE_SEARCH_ITEMS}companyID=${companyID}&clientID=${clientID}&UserID=${userID}&UI-Culture=en-US&searchKeyword=${searchKeyword}&lstColName=${lstColName}`, GETrequestOptions)
  .then(handleErrors)
  .then(response => response.json())
  .then(data =>{
    return data.Obj.ItemAdvanceSearch;
  })
  .catch((error) => {console.log(error); return error;});  
}

export const GetItemUOMByItemID = (token, itemID) =>
{
  const GETrequestOptions = {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization' : "Bearer " + token
    })
  }

  return fetch(`${process.env.REACT_APP_API_GET_ITEM_UOM_BY_ITEMID}itemID=${itemID}&UI-Culture=en-US`, GETrequestOptions)
  .then(handleErrors)
  .then(response => response.json())
  .then(data =>{
    return data.Obj.ItemUOM;
  })
  .catch((error) => {console.log(error); return error;});  
}

export const PostPackageItem = (token, userID, userName, parentItemId, packageItemList, itemUOMList) =>
{
  const POSTrequestOptions = {
    method: 'POST',
    headers: new Headers({
      'accept': 'text/plain',
      'Content-Type': 'application/json-patch+json',//application/json
      'Authorization' : "Bearer " + token
    })
  }

  POSTrequestOptions.body = JSON.stringify(
  {
      "UserID":userID,
      "UserName":userName,
      "PackageItemID":parentItemId.parentItemId,
      "PackageItem":packageItemList,
      "ItemUOM":itemUOMList
  });

  return fetch(`${process.env.REACT_APP_API_POST_PACKAGE_ITEM}UI-Culture=en-US`, POSTrequestOptions)
  .then(handleErrors)
  .then(response => response.json())
  .then(data =>{
    return data.Obj.ParentItemID;
  })
  .catch((error) => {console.log(error); return error;});  
}

export const GetAddNewItemURL = (token, companyID) =>
{
  const GETrequestOptions = {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization' : "Bearer " + token
    })
  }

  return fetch(`${process.env.REACT_APP_API_GET_ITEM_URL}companyID=${companyID}&UI-Culture=en-US`, GETrequestOptions)
  .then(handleErrors)
  .then(response => response.json())
  .then(data =>{
    return data.Obj;
  })
  .catch((error) => {console.log(error); return error;});  
}

export const GetUserGroupAccessRightsByModuleItem = (token, userID, moduleItem, companyID) =>
{
  const GETrequestOptions = {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization' : "Bearer " + token
    })
  }

  return fetch(`${process.env.REACT_APP_API_GET_USER_ACCESS}userID=${userID}&moduleItem=${moduleItem}&companyID=${companyID}&UI-Culture=en-US`, GETrequestOptions)
  .then(handleErrors)
  .then(response => response.json())
  .then(data =>{
    return data.Obj;
  })
  .catch((error) => {console.log(error); return error;});  
}

export const GetItemByItemCode = (token, clientID, iAGID, itemCode) =>
{
  const GETrequestOptions = {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization' : "Bearer " + token
    })
  }

  return fetch(`${process.env.REACT_APP_API_GET_ITEM_BY_CODE}iAGID=${iAGID}&clientID=${clientID}&itemCode=${itemCode}&UI-Culture=en-US`, GETrequestOptions)
  .then(handleErrors)
  .then(response => response.json())
  .then(data =>{
    return data.Obj;
  })
  .catch((error) => {console.log(error); return error;});  
}

//handle errors
function handleErrors(response) {
  if (!response.ok) throw new Error(response.status);
  return response;
}

function handleErrors2(response) {
  if (!response.ok) throw new Error("Session Expired! Please login again.");
  return response;
}

//#endregion
