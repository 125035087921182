import React from 'react';
import { Column, Summary, TotalItem } from 'devextreme-react/data-grid';

import './package-item.scss';
import Flash from '../../../components/message/flash'
import ErrorPopUp from '../../../components/pop-up/error-popup';

//#region customized 
//layout
import { ListingHeader1 } from '../../../layouts';

//default 
import { DefaultPager } from '../../../utils/default-data-grid-settings';
import { DefaultSMIPackageItem, DefaultSMIPackageItemDetails } from '../../../utils/default-smi';
import { CompanyID, UserID, ClientID, AdminYN, SetCookies, SetDecimalPoints, SetDefaultName, DefaultName, Token, SetCompanyID, SetSearchText, SearchText } from '../../../utils/default-cookies';

//api
import { GetPackageItemByCompanyID, GetAllCompaniesIAGByUser, GetUserGroupAccessRightsByModuleItem } from './package-item-services';
import { GetUserLayout } from '../../../api/user-preferred-layout';
import Cookies from 'universal-cookie';

//language
import { formatMessage, locale } from 'devextreme/localization';

//DataGridDefault
import { DataGridDefault, editBtn, customSearch, rowNumCell } from '../../../components';

//MouseTrap
import Mousetrap from 'mousetrap';
//#endregion

const cookies = new Cookies();

class PackageItem extends React.Component {
    constructor(props) {
        super(props);

        this.dataGridRef = React.createRef();

        this.onCellPrepared = this.onCellPrepared.bind(this);
        this.searchValueChangeHandler = this.searchValueChangeHandler.bind(this);
        this.selectValueChangeHandler = this.selectValueChangeHandler.bind(this);
        this.showMsgHandler = this.showMsgHandler.bind(this);
        this.receiveMessage = this.receiveMessage.bind(this);
        this.closeFlashMessageCallback = this.closeFlashMessageCallback.bind(this);
        this.openSourceTab = this.openSourceTab.bind(this);
        this.handleSessionPopUpCallBack = this.handleSessionPopUpCallBack.bind(this);
        this.handleAllowExportGrid = this.handleAllowExportGrid.bind(this);
        this.handleAllowDisplayColumnChooser = this.handleAllowDisplayColumnChooser.bind(this);
        this.handleAllowRestoreLayout = this.handleAllowRestoreLayout.bind(this);
        this.handleAllowSaveGridLayout = this.handleAllowSaveGridLayout.bind(this);
        this.handleNoDataText = this.handleNoDataText.bind(this);
        this.handleDisplayPackageItemDtl = this.handleDisplayPackageItemDtl.bind(this);

        this.onKeyDownMouseTrap = this.onKeyDownMouseTrap.bind(this);
        this.onKeyDownDataGrid = this.onKeyDownDataGrid.bind(this);
        this.onRowDblClick = this.onRowDblClick.bind(this);
        this.redirectDetailPage = this.redirectDetailPage.bind(this);
        this.filterBySearchText = this.filterBySearchText.bind(this);

        this.onFlashMessageTimer = this.onFlashMessageTimer.bind(this);

        this.state = {
            searchText: '',
            PackageItems: [],
            companies: [],
            emptyMsg: '',
            saveEdit: false,
            displayFlashMessageDialog: false,
            displaySessionPopUp: false,
            status: 'success',
            size: 'flash-message-success-container',
            allowExportGrid: false,
            allowDisplayColumnChooser: false,
            allowRestoreLayout: false,
            allowSaveGridLayout: false,
            displayPackageItemDtl: true,
            noDataText: formatMessage("Loading"),
            preferedLayout: [],
        };

        this.handleCompanies = this.handleCompanies.bind(this);
        this.handlePreferedLayout = this.handlePreferedLayout.bind(this);
        this.handlePackageItems = this.handlePackageItems.bind(this);
        this.handleSaveEdit = this.handleSaveEdit.bind(this);

        //handle idle time
        this.idleTime = 0;
        this.handleTimer = this.handleTimer.bind(this);
        this.resetTimer = this.resetTimer.bind(this);
    }

    get dataGrid() {
        // `current.instance` points to the UI component instance
        return this.dataGridRef.current.instance;
    }

    dataGridNullCheck() {
        return this.dataGridRef.current != null;
    }

    handlePackageItems = (result) => {
        this.setState({ PackageItems: result });
    }

    handleCompanies = (result) => {
        this.setState({ companies: result });
    }

    handlePreferedLayout = (result) => {
        this.setState({ preferedLayout: result });
    }

    handleSaveEdit = (result) => {
        this.setState({ saveEdit: result });
    }

    handleAllowDisplayColumnChooser = (result) => {
        this.setState({ allowDisplayColumnChooser: result });
    }

    handleAllowExportGrid = (result) => {
        this.setState({ allowExportGrid: result });
    }

    handleAllowRestoreLayout = (result) => {
        this.setState({ allowRestoreLayout: result });
    }

    handleAllowSaveGridLayout = (result) => {
        this.setState({ allowSaveGridLayout: result });
    }

    handleNoDataText = (result) => {
        this.setState({ noDataText: formatMessage(result) });
    }

    handleDisplayPackageItemDtl = (result) => {
        this.setState({ displayPackageItemDtl: result });
    }

    //reset idle time to 0
    resetTimer() {
        this.idleTime = 0;
    }

    //handle idle time
    handleTimer() {
        this.idleTime = this.idleTime + 1;
        if (this.idleTime > 600) {
            localStorage.setItem("SearchText", this.state.searchText);
            window.location.reload();
        }
    }

    componentDidMount() {
        //get data from iframe
        this.setState({ searchText: SearchText() })

        window.addEventListener("message", this.receiveMessage, false);
        document.addEventListener('keydown', this.onFlashMessageTimer, false);
        document.addEventListener('mousedown', this.onFlashMessageTimer, false);
        document.addEventListener('mousemove', this.resetTimer, false);

        Mousetrap.bind('enter', this.onKeyDownMouseTrap);

        this.idleInterval = setInterval(this.handleTimer, 1000);
    }

    componentWillUnmount() {
        //remove event listener
        window.removeEventListener("message", this.receiveMessage, false);
        document.removeEventListener('keydown', this.onFlashMessageTimer, false);
        document.removeEventListener('mousedown', this.onFlashMessageTimer, false);
        document.removeEventListener('mousemove', this.resetTimer, false);

        Mousetrap.unbind('enter');

        clearInterval(this.idleInterval);
    }

    async receiveMessage(e) {
        this.handleNoDataText("Loading");

        // to retain the search text value if idle time exceeded to reload page 
        if (localStorage.getItem("SearchText") !== null) {
            this.setState({ searchText: localStorage.getItem("SearchText") });
            SetSearchText(cookies, localStorage.getItem("SearchText"));
            localStorage.setItem("SearchText", "");
        }
        //else
        //{
        //  this.setState({ searchText: ""});
        //  SetSearchText(cookies, "");
        //}

        if (e.data != "empty") {
            SetCookies(cookies, e.data.accessToken, e.data.userID, e.data.companyID, e.data.clientID, e.data.username, e.data.adminYN, e.data.administratorYN, e.data.language);

            //language
            sessionStorage.setItem("locale", e.data.language);
            const locales = sessionStorage.getItem("locale");
            locale(locales);
        }

        //POST request using fetch with error handling
        const [dataSource_PackageItem2, dataSource_PackageItem, UserPreferedLayout, UserAccessControl, PackageItemDtlAccessControl] = await Promise.all([
            GetAllCompaniesIAGByUser(Token(), ClientID(), UserID(), AdminYN()),
            GetPackageItemByCompanyID(Token(), CompanyID(), ClientID(), UserID(), AdminYN(), this.state.searchText, 'IM_ID|IM_Code|IM_Description|IM_Description2|IC_Description|IG_Description'),
            GetUserLayout(Token(), UserID(), DefaultSMIPackageItem.moduleURL, DefaultSMIPackageItem.controlID),
            GetUserGroupAccessRightsByModuleItem(Token(), UserID(), DefaultSMIPackageItem.moduleURL, CompanyID()),
            GetUserGroupAccessRightsByModuleItem(Token(), UserID(), DefaultSMIPackageItemDetails.moduleURL, CompanyID()),
        ])

        if (dataSource_PackageItem2 == 'Error: 401' || dataSource_PackageItem == 'Error: 401' || UserPreferedLayout == 'Error: 401' || UserAccessControl == 'Error: 401' || PackageItemDtlAccessControl == 'Error: 401') {
            this.handleSessionPopUpCallBack();
        }
        else {
            var currentCompanyData = dataSource_PackageItem2.find(x => x.CO_ID == CompanyID());
            SetDefaultName(cookies, currentCompanyData.CO_Name);
            SetDecimalPoints(cookies, currentCompanyData.IAG_ItemRounding);

            this.handleCompanies(dataSource_PackageItem2);
            this.handlePackageItems(dataSource_PackageItem);
            this.handlePreferedLayout(UserPreferedLayout);

            if (AdminYN() == "true") {
                this.handleSaveEdit(true);

                this.handleAllowDisplayColumnChooser(true);
                this.handleAllowExportGrid(true);
                this.handleAllowRestoreLayout(true);
                this.handleAllowSaveGridLayout(true);
                this.handleDisplayPackageItemDtl(true);
            }
            else {
                this.handleSaveEdit(UserAccessControl.Query_UserModuleItemAccessSimplified[0].SaveEdit);
                this.handleAllowDisplayColumnChooser(UserAccessControl.Query_UserModuleItemAccessSimplified[0].GridColumnChooser);
                this.handleAllowExportGrid(UserAccessControl.Query_UserModuleItemAccessSimplified[0].ExportGrid);
                this.handleAllowRestoreLayout(UserAccessControl.Query_UserModuleItemAccessSimplified[0].RestoreGridLayout);
                this.handleAllowSaveGridLayout(UserAccessControl.Query_UserModuleItemAccessSimplified[0].SaveGridLayout);
                this.handleDisplayPackageItemDtl(PackageItemDtlAccessControl.Query_UserModuleItemAccessSimplified[0].View);
            }
        }

        this.handleNoDataText("dxDataGrid-noDataText");
    }

    onFlashMessageTimer() {
        if (this.state.displayFlashMessageDialog === true) {
            setTimeout(() => this.setState({ displayFlashMessageDialog: false }), 1000)
        }
    }

    searchValueChangeHandler(event) {
        SetSearchText(cookies, event);
        this.setState({ searchText: event });
        const dataSource_PackageItem = GetPackageItemByCompanyID(Token(), CompanyID(), ClientID(), UserID(), AdminYN(), this.state.searchText, 'IM_Code|IM_Description|IM_Description2|IC_Description|IG_Description');
        // don't include column IM_ID when query for search

        this.handlePackageItems(dataSource_PackageItem);

        // filterBySearchText after DOM elements are updated (new data source loaded)
        setTimeout(() => {
            this.filterBySearchText();
        }, 0);
    }

    async selectValueChangeHandler(event) {
        SetCompanyID(cookies, event);
        this.handleNoDataText("Loading");

        const [dataSource_Company, dataSource_PackageItem, UserAccessControl, PackageItemDtlAccessControl] = await Promise.all([
            GetAllCompaniesIAGByUser(Token(), ClientID(), UserID(), AdminYN()),
            GetPackageItemByCompanyID(Token(), CompanyID(), ClientID(), UserID(), AdminYN(), this.state.searchText, 'IM_ID|IM_Code|IM_Description|IM_Description2|IC_Description|IG_Description'),
            GetUserGroupAccessRightsByModuleItem(Token(), UserID(), DefaultSMIPackageItem.moduleURL, CompanyID()),
            GetUserGroupAccessRightsByModuleItem(Token(), UserID(), DefaultSMIPackageItemDetails.moduleURL, CompanyID())
        ])

        if (dataSource_Company == 'Error: 401' || dataSource_PackageItem == 'Error: 401' || UserAccessControl == 'Error: 401' || PackageItemDtlAccessControl == 'Error: 401') {
            this.handleSessionPopUpCallBack();
        }
        else {
            var currentCompanyData = dataSource_Company.find(x => x.CO_ID == CompanyID());
            SetDefaultName(cookies, currentCompanyData.CO_Name);
            SetDecimalPoints(cookies, currentCompanyData.IAG_ItemRounding);

            if (AdminYN() == "true") {
                this.handleSaveEdit(true);
                this.handleAllowDisplayColumnChooser(true);
                this.handleAllowExportGrid(true);
                this.handleAllowRestoreLayout(true);
                this.handleAllowSaveGridLayout(true);
                this.handleDisplayPackageItemDtl(true);
            }
            else {
                this.handleSaveEdit(UserAccessControl.Query_UserModuleItemAccessSimplified[0].SaveEdit);
                this.handleAllowDisplayColumnChooser(UserAccessControl.Query_UserModuleItemAccessSimplified[0].GridColumnChooser);
                this.handleAllowExportGrid(UserAccessControl.Query_UserModuleItemAccessSimplified[0].ExportGrid);
                this.handleAllowRestoreLayout(UserAccessControl.Query_UserModuleItemAccessSimplified[0].RestoreGridLayout);
                this.handleAllowSaveGridLayout(UserAccessControl.Query_UserModuleItemAccessSimplified[0].SaveGridLayout);
                this.handleDisplayPackageItemDtl(PackageItemDtlAccessControl.Query_UserModuleItemAccessSimplified[0].View);
            }

            this.handleCompanies(dataSource_Company);
            this.handlePackageItems(dataSource_PackageItem);
        }

        this.handleNoDataText("dxDataGrid-noDataText");
    }

    showMsgHandler(params, value, size) {
        if (params !== '') {
            this.setState({ emptyMsg: params, displayFlashMessageDialog: true, status: value, size: size });
        }
        else {
            this.setState({ emptyMsg: '', displayFlashMessageDialog: false, status: value, size: size });
        }
    }

    closeFlashMessageCallback() {
        this.setState(state => ({
            displayFlashMessageDialog: !state.displayFlashMessageDialog
        }))
    }

    handleSessionPopUpCallBack() {
        this.setState(state => ({
            displaySessionPopUp: !state.displaySessionPopUp
        }))
    }

    onCellPrepared(e) {
        if (e.rowType === "totalFooter") {
            if (e.column.type === "buttons") {
                const summaryValue = e.component.getTotalSummaryValue("totalCount");
                // create the required HTML element with 'summaryValue' and append it to 'e.cellElement'
                const span = document.createElement("span");
                span.className = "dx-datagrid-summary-item"
                span.innerHTML = formatMessage("Records") + ": " + summaryValue;
                e.cellElement.appendChild(span);
            }
            if (e.column.dataField === "IM_Code") {
                e.cellElement.innerHTML = "";
            }
        }
    }

    openSourceTab(data) {
        window.parent.postMessage(this.getPostMessageObj(data.IM_Code_URL), "*");
    }

    getPostMessageObj(url) {
        return {
            source: window.location.host,
            target: process.env.ALAYA_APP_API_BASE_URL,
            eval: "parent.closeTab('Item Creation Details');parent.addTab('Item Creation Details', '" + url + "')"
        }
    }

    async onKeyDownMouseTrap() {
        if (this.state.displayPackageItemDtl === true) {
            const data = await this.getFocusedRowData();
            if (data) this.redirectDetailPage(data);
        }
    }

    async onKeyDownDataGrid(data) {
        if (data.event && data.event.key === "Enter" && this.state.displayPackageItemDtl === true) {
            const data = await this.getFocusedRowData();
            if (data) this.redirectDetailPage(data);
        }
    }

    onRowDblClick(event) {
        if (this.state.displayPackageItemDtl === true) {
            this.redirectDetailPage(event.data);
        }
    }

    getFocusedRowData() {
        try {
            const focusedRowKey = this.dataGrid.option("focusedRowKey");
            return this.dataGrid.byKey(focusedRowKey).then(
                (data) => { return data },
                (error) => { /* focusedRow not found */ }
            );
        } catch (error) {
            // focusedRow not found
        }
    }

    redirectDetailPage = (data) => {
        if (!data) return;

        const id = data.IM_ID;
        const code = data.IM_Code;
        const desp = data.IM_Description;

        if (id === undefined) return;

        if (this.state.displayPackageItemDtl === true) {
            //Set company ID to int
            let objCompany = this.state.companies.find(c => c.CO_ID == CompanyID());
            var iag = objCompany.CO_IAG;
            var iag_desp = objCompany.IAG_Description;
            var destinationURL = "/#/package-item-details?id=" + encodeURIComponent(id + "&code=" + code + "&desp=" + desp + "&iag=" + iag + '&iag_desp=' + iag_desp);

            window.open(destinationURL, "_self");
        }
        else if (this.state.displayPackageItemDtl === false && this.state.saveEdit !== false) {
            window.open('/#/access-denied', "_self");
        }
    }

    filterBySearchText() {
        const searchQuery = this.state.searchText.trim();

        if (searchQuery.length === 0) {
            const getValue = () => true;
            this.dataGrid.filter([getValue, '=', true]);
            this.dataGrid.option('navigateToFirst', true);
            return;
        }

        const regExpText = "(".concat(
            searchQuery
                .replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&')
                .split(" ")
                .join(")|(")
                .concat(")")
        );

        const regExp = new RegExp(regExpText, 'i');

        let columnConfig = [];

        this.dataGrid.getVisibleColumns().forEach(function (column) {
            if (column.hasCustomSearch) {
                const dataField = column.dataField;
                const format = column?.format;
                columnConfig.push({ dataField, format });
            }
        });

        const getValue = (column) => {
            return columnConfig.some(function (config) {
                let value = column[config.dataField];

                if (config.format) {
                    // to search columns with different format text
                    const base = 10 ** config.format.precision;
                    value = (Math.round(parseFloat((value * base).toFixed(10))) / base).toFixed(config.format.precision);
                }

                return regExp.test(value);
            });
        }

        this.dataGrid.filter([getValue, '=', true]);
        this.dataGrid.option('navigateToFirst', true);
    }
    //#endregion

    render() {
        return (
            <>
                <ErrorPopUp
                    parentCallback={this.handleSessionPopUpCallBack}
                    visible={this.state.displaySessionPopUp}
                    title={formatMessage('SessionExpired')}
                    subTitle={formatMessage('PleaseLoginAgain')}
                />

                <div className="module-navbar">
                    <Flash
                        parentCallback={this.closeFlashMessageCallback}
                        message={this.state.emptyMsg}
                        visible={this.state.displayFlashMessageDialog}
                        severity={this.state.status}
                        container={this.state.size}
                    />
                </div>

                <div className="module-wrapper">
                    <div className="package-item-header-title">
                        <ListingHeader1
                            placeHolderText={formatMessage('QuickSearchPlaceholderText')}
                            buttonText={formatMessage('Search')}
                            captionDropdown={formatMessage('Company')}
                            captionTextbox={formatMessage('AssociatedItemGroup')}
                            dataSource={this.state.companies}
                            defaultValue={CompanyID()}
                            defaultName={DefaultName()}
                            nameProp={'CO_Name'}
                            valueProp={'CO_ID'}
                            displayProp={'IAG_Description'}
                            visible={true}
                            searchTextValue={this.state.searchText}
                            onSearchValueChange={this.searchValueChangeHandler}
                            onSelectValueChange={this.selectValueChangeHandler}
                        />
                    </div>

                    <div className="module-data-grid">
                        <DataGridDefault
                            allowDisplayColumnChooser={this.state.allowDisplayColumnChooser}
                            allowExportGrid={this.state.allowExportGrid}
                            allowedPageSizes={DefaultPager.allowedPageSizes_listing}
                            allowRestoreLayout={this.state.allowRestoreLayout}
                            allowSaveGridLayout={this.state.allowSaveGridLayout}
                            className='dx-datagrid-items'
                            dataGridRef={this.dataGridRef}
                            dataSource={this.state.PackageItems}
                            defaultPageSize={DefaultPager.defaultPageSize_listing}
                            defaultSMI={DefaultSMIPackageItem}
                            focusedRowEnabled={true}
                            keyExpr="IM_ID"
                            noDataText={this.state.noDataText}
                            onCellPrepared={this.onCellPrepared}
                            onKeyDown={this.onKeyDownDataGrid}
                            onRowDblClick={this.onRowDblClick}
                            preferedLayout={this.state.preferedLayout}
                            showMsgHandler={this.showMsgHandler}
                        >
                            <Column
                                width={50}
                                minWidth={50}
                                allowHiding={false}
                                showInColumnChooser={false}
                                type="buttons"
                                allowResizing={false}
                                cellRender={editBtn({ onClick: this.onRowDblClick, enabled: this.state.displayPackageItemDtl })}
                                fixed={true}
                                fixedPosition={'left'}
                            />
                            <Column
                                caption={'No.'}
                                width={60}
                                minWidth={60}
                                fixed={true}
                                fixedPosition={'left'}
                                alignment={'left'}
                                allowResizing={false}
                                allowSorting={false}
                                cellRender={rowNumCell()}
                            />
                            <Column
                                dataField={'IM_ID'}
                                width={0}
                                allowHiding={false}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField={'IM_Code'}
                                caption={formatMessage('ItemCode')}
                                width={120}
                                minWidth={60}
                                cellRender={customSearch({ allowSearch: true, link: true, searchText: this.state.searchText, onClick: this.openSourceTab })}
                                hasCustomSearch={true}
                            />
                            <Column
                                dataField={'IM_Description'}
                                caption={formatMessage('Description')}
                                minWidth={60}
                                cellRender={customSearch({ allowSearch: true, searchText: this.state.searchText })}
                                hasCustomSearch={true}
                            />
                            <Column
                                dataField={'IM_Description2'}
                                caption={formatMessage('Description2')}
                                minWidth={60}
                                cellRender={customSearch({ allowSearch: true, searchText: this.state.searchText })}
                                hasCustomSearch={true}
                            />
                            <Column
                                dataField={'IG_Description'}
                                caption={formatMessage('ItemGroup')}
                                minWidth={60}
                                cellRender={customSearch({ allowSearch: true, searchText: this.state.searchText })}
                                hasCustomSearch={true}
                            />
                            <Column
                                dataField={'IC_Description'}
                                caption={formatMessage('ItemCategory')}
                                minWidth={60}
                                cellRender={customSearch({ allowSearch: true, searchText: this.state.searchText })}
                                hasCustomSearch={true}
                            />
                            <Summary>
                                <TotalItem
                                    name="totalCount"
                                    summaryType="count"
                                    displayFormat="Count: {0}"
                                    showInColumn="IM_Code" />
                            </Summary>
                        </DataGridDefault>
                    </div>
                </div>
            </>
        );
    }
}
export default PackageItem;
