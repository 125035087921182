import React, { forwardRef, useImperativeHandle, useEffect, useState, useRef } from 'react';
import TextBox from 'devextreme-react/text-box';
import { Button } from 'devextreme-react';
import ReactTooltip from 'react-tooltip';
import DefaultDataLimit from '../../utils/default-data-limit';

const TextBox4 =  forwardRef(({ placeHolderText, tooltipText, value, onTbvalueChange, openItemPopUp, visible }, ref) => {
    const textBoxRef = useRef();
    const [tbValue, setTbValue] = useState(value);
    useEffect(() => {
        textBoxRef.current.instance.focus();
    },[]);

    useImperativeHandle(ref, () => ({
            setFocus(e) {
                tb_setFocus(e);
            },
            clear(e) {
                tb_clear(e);
            },
            setValue(e){
                tb_setValue(e);
            }
        }),
    )

    function tb_onChangedHandler(event){

        // to control Qty"*" function key in 
        if(event.value.substring(0,1) === "*")
        {
            var qtyInput = "*" + event.value.replaceAll(/[^0-9.]/g,'');

            if (qtyInput.lastIndexOf(".") !== -1){
                
                //to control max 12 digits before decimal points & max 5 decimal places
                var qtyInputDigits  = qtyInput.substring(0,qtyInput.lastIndexOf(".") > DefaultDataLimit.qtyMaxDigitsCheckIndex ? DefaultDataLimit.qtyMaxDigitsCheckIndex : qtyInput.lastIndexOf("."));
                var qtyInputDecimal = qtyInput.substring(qtyInput.lastIndexOf("."), (qtyInput.length - qtyInput.lastIndexOf(".")) > DefaultDataLimit.qtyMaxDecimalPointsCheckIndex ? (qtyInput.lastIndexOf(".") + DefaultDataLimit.qtyMaxDecimalPointsCheckIndex) : qtyInput.length);
                setTbValue(qtyInputDigits + qtyInputDecimal);
                onTbvalueChange(qtyInputDigits + qtyInputDecimal);
                
            }
            else
            {
                setTbValue(qtyInput.substring(0, DefaultDataLimit.qtyMaxDigitsCheckIndex));
                onTbvalueChange(qtyInput.substring(0, DefaultDataLimit.qtyMaxDigitsCheckIndex));
            }
        }
        else
        {
            setTbValue(event.value);
            onTbvalueChange(event.value);
        }
    }

    function open_itemPopUp(){
        openItemPopUp();
    }

    function tb_setFocus(e)
    {
        textBoxRef.current.instance.focus();
    }

    function tb_clear(e)
    {
        textBoxRef.current.instance.reset();
    }

    function tb_setValue(e)
    {
        setTbValue(e);
        onTbvalueChange(e);
    }
    return (
        <div data-tip={tooltipText} data-for="inputCode">

            <div className="dx-fieldset">
                <div className="dx-field">
                    <div className="dx-field-value" >
                        <TextBox 
                            className="mousetrap"
                            value={tbValue}
                            stylingMode={'outlined'}
                            placeholder={placeHolderText}
                            mode='search'
                            valueChangeEvent='keyup'
                            onValueChanged={tb_onChangedHandler}
                            visible = {visible}                            
                            ref={textBoxRef}>
                        </TextBox>

                        <Button
                            className="search-button"
                            onClick={open_itemPopUp}
                            visible={visible}>
                            <div className="icon-search-icon"/>
                        </Button>
                    </div>
                </div>
            </div>  

            <ReactTooltip id="inputCode" effect="solid" arrowColor="#474751" place='bottom'/>
        </div>
    );
})

export default TextBox4;