import React from 'react';
import Form from 'devextreme-react/form';
import Cookies from 'universal-cookie';
import { ListingHeader1 } from '../../../layouts';
import { Item } from 'devextreme-react/select-box';
import { NumberBox } from 'devextreme-react/number-box';
import { locale, formatMessage } from 'devextreme/localization';
import { Flash, Toolbar1, TextboxChecker } from '../../../components/index';
import { GetCostMappingByCompanyID, PostCostMapping } from './cost-mapping-service';
import { GetAllCompaniesIAGByUser, GetUserGroupAccessRightsByModuleItem } from '../package-item/package-item-services';
import { CompanyID, UserID, ClientID, AdminYN, SetCookies, SetDefaultName, DefaultName, Token, SetCompanyID } from '../../../utils/default-cookies';
import ErrorPopUp from '../../../components/pop-up/error-popup';
import './cost-mapping.scss';

const cookies = new Cookies();
const VALID_STATE = 0;
const DUPLICATED_VALUE = 1;
const INPUT_REQUIRED = 2;
const INVALID_SYMBOL = 3;
const SYMBOL = /["'\\/\s]/; 
class CostMapping extends React.Component {

    constructor(props) {
        super(props);

        this.tb1InputRef = React.createRef();

        // this.saveCallback = this.saveCallback.bind(this);
        // this.hideSaveConfirmationDialog = this.hideSaveConfirmationDialog.bind(this);

        this.onCheckTbValue = this.onCheckTbValue.bind(this);
        this.onCheckEmptyTb = this.onCheckEmptyTb.bind(this);
        this.showMsgHandler = this.showMsgHandler.bind(this);
        this.handleCompanies = this.handleCompanies.bind(this);
        this.setDefaultChecker = this.setDefaultChecker.bind(this);
        this.setDefaultDisabler = this.setDefaultDisabler.bind(this);
        this.handleValueChanged = this.handleValueChanged.bind(this);
        this.handleDigitChanged = this.handleDigitChanged.bind(this);
        this.onFlashMessageTimer = this.onFlashMessageTimer.bind(this);
        this.saveCostMapping = this.saveCostMapping.bind(this);
        this.handleCostMappingLayout = this.handleCostMappingLayout.bind(this);
        this.handleCallCostMappingAPI = this.handleCallCostMappingAPI.bind(this);
        this.updateCostMappingDetails = this.updateCostMappingDetails.bind(this);
        this.selectValueChangeHandler = this.selectValueChangeHandler.bind(this);
        this.closeFlashMessageCallback = this.closeFlashMessageCallback.bind(this);
        this.receiveMessage = this.receiveMessage.bind(this); 
        this.handleSessionPopUpCallBack = this.handleSessionPopUpCallBack.bind(this);
        this.handleAllowSave = this.handleAllowSave.bind(this);

        this.state = {
            companies : [],
            emptyMsg: '',
            iAGID: '',
            mappingDigit_0: '',
            mappingDigit_1: '',
            mappingDigit_2: '',
            mappingDigit_3: '',
            mappingDigit_4: '',
            mappingDigit_5: '',
            mappingDigit_6: '',
            mappingDigit_7: '',
            mappingDigit_8: '',
            mappingDigit_9: '',
            mappingDigit_P: '',
            dateCreated: '',
            cm_id: 0,
            leadingDigit: 2,
            checker0: 0,
            checker1: 0,
            checker2: 0,
            checker3: 0,
            checker4: 0,
            checker5: 0,
            checker6: 0,
            checker7: 0,
            checker8: 0,
            checker9: 0,
            checker10: 0,
            checker11: 0,
            disable0: false,
            disable1: false,
            disable2: false,
            disable3: false,
            disable4: false,
            disable5: false,
            disable6: false,
            disable7: false,
            disable8: false,
            disable9: false,
            disable10: false,
            displayFlashMessageDialog: false,
            status: 'success',
            size: 'flash-message-success-container',
            displaySessionPopUp: false,
            allowSave: false
            // displaySaveConfirmationDialog: false,
        }
    }

    componentDidMount(){ 
        window.addEventListener('message', this.receiveMessage, false);
        document.addEventListener('keydown', () => this.onFlashMessageTimer(), false);
        document.addEventListener('mousedown', () => this.onFlashMessageTimer(), false);        
        //this.receiveMessage("");
    }
    
    componentWillUnmount(){
        window.removeEventListener('message', this.receiveMessage, false);
        document.removeEventListener('keydown', () => this.onFlashMessageTimer(), false);
        document.removeEventListener('mousedown', () => this.onFlashMessageTimer(),false);
    }

    async receiveMessage(e){  
        if(e.data !== "empty") 
        {
            //SetCookies(cookies, "eyJhbGciOiJSUzI1NiIsImtpZCI6IjQyMEU5NjI2QzYxNTY1NEQyRDUzNjlBMEFBNTc3OEFBIiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2MjY5MjIwNTksImV4cCI6MTYyNzAwODQ1OSwiaXNzIjoiaHR0cDovL3Rlc3RhcGkuaXJzYWxheWEuY29tIiwiY2xpZW50X2lkIjoiYXBpIiwic3ViIjoiYWRtaW4iLCJhdXRoX3RpbWUiOjE2MjY5MjIwNTksImlkcCI6ImxvY2FsIiwiTG9naW5BcyI6IlVzZXIiLCJDbGllbnRJRCI6InJlYWN0IiwiQ25zTmFtZSI6IkFsYXlhRGJFeGFieXRlIiwiRGJOYW1lIjoiSVJTQWNjb3VudGluZ19JUlMwMTA0X1JFQUNUIiwianRpIjoiMDU1QkE3MEFGNzdCRjY5OUQxNzdBMUMxRTkxMUExMjciLCJpYXQiOjE2MjY5MjIwNTksInNjb3BlIjpbImFwaTEiLCJvZmZsaW5lX2FjY2VzcyJdLCJhbXIiOlsicGFzc3dvcmQiXX0.a-yQXLswWCzq_UL1UxeDMXgq6GTPrJyOQBJcBOOWMr2N1NOeTne5TfZO5zwgsOgrY0Uwcl7yW3E5M7UCGB77dANj1wm6xkymLIpP_3HKjc3ehCv3Rx7TtgzJxPD3vwY7OPkFzfUMLb7n-V_Q013AHntHUzUCcTBLO_YyZFvx5SR1lEpyJppdKi9RhsEqpG0yIDXck0wQjhUBSAkHPx6BdU5olEjK-u9n_MXNclVhV54nPtdpzyokF2ByXAAehhBlsafG4O1aGeNsEUTtxRous2W1I0QzZXIpnjlq4Nn9zY35io80Zlzqrimx8lrXFfUL5JPloYJlV3E8XkFHf4BU6w", 2, 392, 1, "IRS ADMIN", true, false);  
            SetCookies(cookies, e.data.accessToken, e.data.userID, e.data.companyID, e.data.clientID, e.data.username, e.data.adminYN, e.data.administratorYN, e.data.language);

            sessionStorage.setItem("locale", e.data.language);
            const locales = sessionStorage.getItem("locale");
            locale(locales);
        }
        
        const [dataSource_getAllCompanyByUser, dataSource_getCostMappingByCompanyID, UserAccessControl]  = await Promise.all([
            GetAllCompaniesIAGByUser(Token(), ClientID(), UserID(), AdminYN()),
            GetCostMappingByCompanyID(Token(), CompanyID(), ClientID()),
            GetUserGroupAccessRightsByModuleItem(Token(), UserID(), 'r_CostMapping.aspx', CompanyID())
        ])

        if(dataSource_getAllCompanyByUser == 'Error: 401' || dataSource_getCostMappingByCompanyID == 'Error: 401' || UserAccessControl == 'Error: 401'){
            this.handleSessionPopUpCallBack();
        }
        else{
            var currentCompanyData = dataSource_getAllCompanyByUser.find(x => x.CO_ID == CompanyID());

            SetDefaultName(cookies, currentCompanyData.CO_Name);
            this.setState({ iAGID: currentCompanyData.CO_IAG});
            this.handleCompanies(dataSource_getAllCompanyByUser);
            this.handleCostMappingLayout(dataSource_getCostMappingByCompanyID); 

            if(AdminYN() == "true"){
                this.handleAllowSave(true);
            }
            else{
                this.handleAllowSave(UserAccessControl.Query_UserModuleItemAccessSimplified[0].SaveEdit);
            }
        }
    }

    async handleCallCostMappingAPI(){
        const [dataSource_getCostMappingByCompanyID] = await Promise.all([ GetCostMappingByCompanyID(Token(), CompanyID(), ClientID())]);
        this.handleCostMappingLayout(dataSource_getCostMappingByCompanyID); 
    }

    async selectValueChangeHandler(event){ 
        SetCompanyID(cookies, event);
        this.handleCallCostMappingAPI();
    }

    async updateCostMappingDetails(){
        var prevDate = '';
        var dateTime = new Date().toLocaleTimeString();
        //var json = JSON.stringify(date);
        //var dateTime = JSON.parse(json); 

        (this.state.dateCreated == null) ? prevDate = dateTime : prevDate = this.state.dateCreated;

        if(this.state.checker0 === VALID_STATE && this.state.checker1 === VALID_STATE && this.state.checker2 === VALID_STATE && this.state.checker3 === VALID_STATE &&
           this.state.checker4 === VALID_STATE && this.state.checker5 === VALID_STATE && this.state.checker6 === VALID_STATE && this.state.checker7 === VALID_STATE &&
           this.state.checker8 === VALID_STATE && this.state.checker9 === VALID_STATE && this.state.checker10 === VALID_STATE && this.state.checker10 === VALID_STATE) 
           {
                const [newCostMapping]  = await Promise.all
                ([
                    PostCostMapping(Token(), this.state.cm_id, ClientID(), CompanyID(), this.state.iAGID, this.state.mappingDigit_0, 
                    this.state.mappingDigit_1, this.state.mappingDigit_2, this.state.mappingDigit_3, this.state.mappingDigit_4, 
                    this.state.mappingDigit_5, this.state.mappingDigit_6, this.state.mappingDigit_7, this.state.mappingDigit_8, 
                    this.state.mappingDigit_9, this.state.mappingDigit_P, this.state.leadingDigit, prevDate, UserID(), dateTime, UserID())
                ])
                
                if(newCostMapping == 'Error: 401'){
                    this.handleSessionPopUpCallBack();
                }

                if(newCostMapping.Result == false){
                    console.log('invalid companyID');
                    return 0;
                }

                this.handleCallCostMappingAPI();
                this.showMsgHandler(formatMessage('SavedSuccessfully'), 'success', 'flash-message-success-container')
                console.log('Company id', CompanyID())
                console.log(newCostMapping)
           } 
           else 
           { 
                return 0 
           }
    }

    saveCostMapping(){
        this.onCheckEmptyTb()
        this.updateCostMappingDetails()
    }
    
    handleCompanies = (result) => {
        this.setState({ companies: result });
    }

    handleAllowSave = (result) => {
        this.setState({ allowSave: result });
    }

    //handle default and other companies 
    handleCostMappingLayout = (data) => {

        this.setState({ leadingDigit: 0})
        this.setDefaultDisabler(false);
        this.setDefaultChecker(0);
        this.tb1InputRef.current.focus();

        //set to default when data is empty
        if(!data){
            this.setState({
                cm_id: 0,
                dateCreated: new Date().toLocaleTimeString(),
                mappingDigit_0: 'Z', 
                mappingDigit_1: 'Y', 
                mappingDigit_2: 'E',
                mappingDigit_3: 'S', 
                mappingDigit_4: 'A', 
                mappingDigit_5: 'W',  
                mappingDigit_6: 'L', 
                mappingDigit_7: 'Q', 
                mappingDigit_8: 'B', 
                mappingDigit_9: 'J', 
                mappingDigit_P: 'D', 
                leadingDigit: 2 
            });
            this.updateCostMappingDetails();
        }
        //assign the data to each state
        else if (data)
        {
            this.setState({ cm_id: data.CM_ID, 
                dateCreated: data.CM_DateCreated, 
                leadingDigit: data.CM_LeadingDigits,
                mappingDigit_0: data.CM_MappingDigit_0,
                mappingDigit_1: data.CM_MappingDigit_1, 
                mappingDigit_2: data.CM_MappingDigit_2, 
                mappingDigit_3: data.CM_MappingDigit_3, 
                mappingDigit_4: data.CM_MappingDigit_4, 
                mappingDigit_5: data.CM_MappingDigit_5, 
                mappingDigit_6: data.CM_MappingDigit_6, 
                mappingDigit_7: data.CM_MappingDigit_7, 
                mappingDigit_8: data.CM_MappingDigit_8, 
                mappingDigit_9: data.CM_MappingDigit_9, 
                mappingDigit_P: data.CM_MappingDigit_P 
            });
        }
    }

    //set the icon in default mode
    setDefaultChecker = (value) => {
        this.setState({
            checker0: value, 
            checker1: value, 
            checker2: value, 
            checker3: value, 
            checker4: value, 
            checker5: value, 
            checker6: value, 
            checker7: value, 
            checker8: value, 
            checker9: value,
            checker10: value
        })
    }

    setDefaultDisabler = (value) => {
        this.setState({
            disable0: value, 
            disable1: value, 
            disable2: value, 
            disable3: value, 
            disable4: value,
            disable5: value, 
            disable6: value, 
            disable7: value, 
            disable8: value, 
            disable9: value, 
            disable10: value, 
        })
    }

    //save flash message 
    onFlashMessageTimer(){
        if(this.state.displayFlashMessageDialog === true){
          setTimeout(() => this.setState({displayFlashMessageDialog: false}), 1000)
        }
    }

    showMsgHandler = (params, value, size) => {
        if(params !== '') {
            this.setState({emptyMsg: params, displayFlashMessageDialog: true, status: value, size: size});
        }
        else {
            this.setState({emptyMsg: '', displayFlashMessageDialog: false, status: value, size: size});
        }
    }

    closeFlashMessageCallback = () => {
        this.setState(state => ({
          displayFlashMessageDialog: !state.displayFlashMessageDialog
        }))
    }

    handleSessionPopUpCallBack = () => {
        this.setState(state => ({
          displaySessionPopUp: !state.displaySessionPopUp
        }))
    }

    //save this file
    // saveCallback = () => {
    //     return null
    // }

    // hideSaveConfirmationDialog = () =>{
    //     this.setState(state => ({
    //         displaySaveConfirmationDialog: !state.displaySaveConfirmationDialog
    //     }));
    // }
    
    //handle onChange value
    handleValueChanged(event) {
        const {value, name, id, align} = event.target;
        const tb_value = value.toUpperCase();

        this.setState({ [id] : tb_value });
        this.onCheckTbValue(tb_value, name, align, id);
    }

    handleDigitChanged(event){
        this.setState({ leadingDigit: event.value })

        if(event.value !== ''){ this.setState({checker11: VALID_STATE})}
    }

    onCheckTbValue(value, name, align, id){

        const idf = [id];

        if(value !== '') {this.setState({ [name]: VALID_STATE})}

        if(value !== '' && (value !== this.state.mappingDigit_0 || this.state.mappingDigit_1 || this.state.mappingDigit_2 || this.state.mappingDigit_3 || this.state.mappingDigit_4|| this.state.mappingDigit_5|| this.state.mappingDigit_6|| this.state.mappingDigit_7|| this.state.mappingDigit_8|| this.state.mappingDigit_9|| this.state.mappingDigit_10)) {
            this.setDefaultDisabler(false);
            this.setDefaultChecker(VALID_STATE);
        }

        if(SYMBOL.test(value) ) { 
            this.setDefaultDisabler(true);
            this.setState({
                [name]: INVALID_SYMBOL,
                [align]: false}) 
        }

        if( !idf.includes("mappingDigit_0") ){
            if(value !== '' && (value === this.state.mappingDigit_0)) {
                this.setDefaultDisabler(true);
                this.setState({ disable0: false, [align]: false, [name]: DUPLICATED_VALUE });
            }
        }
        if ( !idf.includes("mappingDigit_1")){
            if(value !== '' && (value === this.state.mappingDigit_1)) { 
                this.setDefaultDisabler(true);
                this.setState({ disable1: false, [align]: false, [name]: DUPLICATED_VALUE });
            }
        } 
        if ( !idf.includes("mappingDigit_2")){
            if(value !== '' && (value === this.state.mappingDigit_2)) { 
                this.setDefaultDisabler(true);
                this.setState({ disable2: false, [align]: false, [name]: DUPLICATED_VALUE });
            }
        } 
        if ( !idf.includes("mappingDigit_3")){
            if(value !== '' && (value === this.state.mappingDigit_3)) { 
                this.setDefaultDisabler(true);
                this.setState({ disable3: false, [align]: false, [name]: DUPLICATED_VALUE });
            }
        } 
        if ( !idf.includes("mappingDigit_4")){
            if(value !== '' && (value === this.state.mappingDigit_4)) { 
                this.setDefaultDisabler(true);
                this.setState({ disable4: false, [align]: false, [name]: DUPLICATED_VALUE });
            }
        }
        if ( !idf.includes("mappingDigit_5")){
            if(value !== '' && (value === this.state.mappingDigit_5)) { 
                this.setDefaultDisabler(true);
                this.setState({ disable5: false, [align]: false, [name]: DUPLICATED_VALUE });
            }
        }
        if ( !idf.includes("mappingDigit_6")){
            if(value !== '' && (value === this.state.mappingDigit_6)) { 
                this.setDefaultDisabler(true);
                this.setState({ disable6: false, [align]: false, [name]: DUPLICATED_VALUE });
            }
        }
        if ( !idf.includes("mappingDigit_7")){
            if(value !== '' && (value === this.state.mappingDigit_7)) { 
                this.setDefaultDisabler(true);
                this.setState({ disable7: false, [align]: false, [name]: DUPLICATED_VALUE });
            }
        }
        if ( !idf.includes("mappingDigit_8")){
            if(value !== '' && (value === this.state.mappingDigit_8)) { 
                this.setDefaultDisabler(true);
                this.setState({ disable8: false, [align]: false, [name]: DUPLICATED_VALUE });
            }
        }
        if ( !idf.includes("mappingDigit_9")){
            if(value !== '' && (value === this.state.mappingDigit_9)) { 
                this.setDefaultDisabler(true);
                this.setState({ disable9: false, [align]: false, [name]: DUPLICATED_VALUE });
            }
        }
        if ( !idf.includes("mappingDigit_P")){
            if(value !== '' && (value === this.state.mappingDigit_P)) { 
                this.setDefaultDisabler(true);
                this.setState({ disable10: false, [align]: false, [name]: DUPLICATED_VALUE });
            }
        }
    

        if(value === '' && (value !== this.state.mappingDigit_0)) {
            this.setState({checker0: VALID_STATE}); 
        }
        if(value === '' && (value !== this.state.mappingDigit_1)) {
            this.setState({checker1: VALID_STATE});
        }
        if(value === '' && (value !== this.state.mappingDigit_2)) {
            this.setState({checker2: VALID_STATE});
        }
        if(value === '' && (value !== this.state.mappingDigit_3)) {
            this.setState({checker3: VALID_STATE});
        }
        if(value === '' && (value !== this.state.mappingDigit_4)) {
            this.setState({checker4: VALID_STATE});
        }
        if(value === '' && (value !== this.state.mappingDigit_5)) {
            this.setState({ checker5: VALID_STATE});
        } 
        if(value === '' && (value !== this.state.mappingDigit_6)) {
            this.setState({checker6: VALID_STATE});
        }
        if(value === '' && (value !== this.state.mappingDigit_7)) {
            this.setState({checker7: VALID_STATE});
        }
        if(value === '' && (value !== this.state.mappingDigit_8)) {
            this.setState({checker8: VALID_STATE});
        }
        if(value === '' && (value !== this.state.mappingDigit_9)) {
            this.setState({checker9: VALID_STATE});
        }
        if(value === '' && (value !== this.state.mappingDigit_P)) {
            this.setState({checker10: VALID_STATE});
        }

        if(value === '' && (value !== this.state.mappingDigit_0 || this.state.mappingDigit_1|| this.state.mappingDigit_2 || this.state.mappingDigit_3 || this.state.mappingDigit_4 || this.state.mappingDigit_5 || this.state.mappingDigit_6 || this.state.mappingDigit_7||this.state.mappingDigit_8||this.state.mappingDigit_9||this.state.mappingDigit_P)) {
            this.setDefaultDisabler(false);
        }
    }

    onCheckEmptyTb = () => {
        //empty textbox
        if (this.state.leadingDigit === '' || this.state.leadingDigit === null) { this.setState({checker10: INPUT_REQUIRED})}
        if (this.state.mappingDigit_0 === '' || this.state.mappingDigit_0 === null) { this.setState({checker0: INPUT_REQUIRED})}
        if (this.state.mappingDigit_1 === '' || this.state.mappingDigit_1 === null) { this.setState({checker1: INPUT_REQUIRED})}
        if (this.state.mappingDigit_2 === '' || this.state.mappingDigit_2 === null) { this.setState({checker2: INPUT_REQUIRED})}
        if (this.state.mappingDigit_3 === '' || this.state.mappingDigit_3 === null) { this.setState({checker3: INPUT_REQUIRED})}
        if (this.state.mappingDigit_4 === '' || this.state.mappingDigit_4 === null) { this.setState({checker4: INPUT_REQUIRED})}
        if (this.state.mappingDigit_5 === '' || this.state.mappingDigit_5 === null) { this.setState({checker5: INPUT_REQUIRED})}
        if (this.state.mappingDigit_6 === '' || this.state.mappingDigit_6 === null) { this.setState({checker6: INPUT_REQUIRED})}
        if (this.state.mappingDigit_7 === '' || this.state.mappingDigit_7 === null) { this.setState({checker7: INPUT_REQUIRED})}
        if (this.state.mappingDigit_8 === '' || this.state.mappingDigit_8 === null) { this.setState({checker8: INPUT_REQUIRED})}
        if (this.state.mappingDigit_9 === '' || this.state.mappingDigit_9 === null) { this.setState({checker9: INPUT_REQUIRED})}
        if (this.state.mappingDigit_P === '' || this.state.mappingDigit_P === null) { this.setState({checker10: INPUT_REQUIRED})}
    }

    render(){
        return (
            <>
            {/* <SavePopUp 
                 parentCallback = {this.saveCallback}
                 hidePopup      = {this.hideSaveConfirmationDialog}
                 visible        ={this.state.displaySaveConfirmationDialog} /> */}

            <ErrorPopUp 
                parentCallback = {this.handleSessionPopUpCallBack}
                visible={this.state.displaySessionPopUp}
                title ={formatMessage('SessionExpired')}
                subTitle ={formatMessage('PleaseLoginAgain')} />

            <div className="module-navbar">
                <Flash
                    parentCallback  = {this.closeFlashMessageCallback}
                    message         = {this.state.emptyMsg}
                    visible         = {this.state.displayFlashMessageDialog}
                    severity        = {this.state.status}
                    container       = {this.state.size}/>

                <Toolbar1
                    displayBackBtn          = {false} 
                    displaySaveBtn          = {this.state.allowSave}
                    displayCopyBtn          = {false}
                    displayAddNewBtn        = {false}
                    displayPreviewReport    = {false}
                    displayMoreOptions      = {false}
                    displaydocinfo          = {false}
                    displayacposting        = {false}
                    displaypaymenthistory   = {false}
                    displayauditlog         = {false}
                    displaydocmapping       = {false}
                    showSaveMsg             = {this.showMsgHandler}
                    saveLayout              = {this.saveCostMapping}/>
            </div>
                
            <Form
                width= {'100%'}
                colSpan={2}>
                <Item colSpan={1}>
                    <div className="cost-mapping-header">
                        <ListingHeader1
                            visible                 = {false}
                            valueProp               = {'CO_ID'}
                            nameProp                = {'CO_Name'}
                            codeProp                = {'CO_Code'}
                            displayProp             = {'IAG_Description'}
                            defaultValue            = {CompanyID()}
                            defaultName             = {DefaultName()}
                            captionDropdown         = {formatMessage('Company')} 
                            captionTextbox          = {formatMessage('AssociatedItemGroup')} 
                            onSelectValueChange     = {this.selectValueChangeHandler}
                            dataSource              = {this.state.companies}/>
                    </div>
                </Item>

                <Item colSpan={1}>
                    <hr className="line-separator-cost"/>
                </Item> 

                <Item colSpan={2}>
                <   div className="cost-mapping-columns-templete" >
                <div className="cost-mapping-wrapper">
                            <TextboxChecker
                                id             = {'mappingDigit_0'}
                                name           = {'checker0'}
                                align          = {'disable0'}
                                header         = {'inline-block'}
                                value          = {this.state.mappingDigit_0}
                                checker        = {this.state.checker0}
                                disabled       = {this.state.disable0}
                                onChange       = {this.handleValueChanged}
                                maxLength      = {1}
                                tabIndex       = {1}
                                label          = {'0'}
                                ref            = {this.tb1InputRef}/>
 
                            <TextboxChecker
                                id             = {'mappingDigit_1'}
                                name           = {'checker1'}
                                align          = {'disable1'}
                                header         = {'none'}
                                value          = {this.state.mappingDigit_1}
                                checker        = {this.state.checker1}
                                disabled       = {this.state.disable1}
                                onChange       = {this.handleValueChanged}
                                maxLength      = {1}
                                tabIndex       = {2}
                                label          = {'1'}/>
                        
                            <TextboxChecker
                                id             = {'mappingDigit_2'}
                                name           = {'checker2'}
                                align          = {'disable2'}
                                header         = {'none'}
                                value          = {this.state.mappingDigit_2}
                                checker        = {this.state.checker2}
                                disabled       = {this.state.disable2}
                                onChange       = {this.handleValueChanged}
                                maxLength      = {1}
                                tabIndex       = {3}
                                label          = {'2'}/>
                        
                            <TextboxChecker
                                id             = {'mappingDigit_3'}
                                name           = {'checker3'}
                                align          = {'disable3'}
                                header         = {'none'}
                                value          = {this.state.mappingDigit_3}
                                checker        = {this.state.checker3}
                                disabled       = {this.state.disable3}
                                onChange       = {this.handleValueChanged}
                                maxLength      = {1}
                                tabIndex       = {4}
                                label          = {'3'}/>
                        
                            <TextboxChecker
                                id             = {'mappingDigit_4'}
                                name           = {'checker4'}
                                align          = {'disable4'}
                                header         = {'none'}
                                value          = {this.state.mappingDigit_4}
                                checker        = {this.state.checker4}
                                disabled       = {this.state.disable4}
                                onChange       = {this.handleValueChanged}
                                maxLength      = {1}
                                tabIndex       = {5}
                                label          = {'4'}/>

                            <TextboxChecker
                                id             = {'mappingDigit_5'}
                                name           = {'checker5'}
                                align          = {'disable5'}
                                header         = {'none'}
                                value          = {this.state.mappingDigit_5}
                                checker        = {this.state.checker5}
                                disabled       = {this.state.disable5}
                                onChange       = {this.handleValueChanged}
                                maxLength      = {1}
                                tabIndex       = {6}
                                label          = {'5'}/>

                            <TextboxChecker
                                id             = {'mappingDigit_6'}
                                name           = {'checker6'}
                                align          = {'disable6'}
                                header         = {'none'}
                                value          = {this.state.mappingDigit_6}
                                checker        = {this.state.checker6}
                                disabled       = {this.state.disable6}
                                onChange       = {this.handleValueChanged}
                                maxLength      = {1}
                                tabIndex       = {7}
                                label          = {'6'}/>

                            <TextboxChecker
                                id             = {'mappingDigit_7'}
                                name           = {'checker7'}
                                align          = {'disable7'}
                                header         = {'none'}
                                value          = {this.state.mappingDigit_7}
                                checker        = {this.state.checker7}
                                disabled       = {this.state.disable7}
                                onChange       = {this.handleValueChanged}
                                maxLength      = {1}
                                tabIndex       = {8}
                                label          = {'7'}/>

                            <TextboxChecker
                                id             = {'mappingDigit_8'}
                                name           = {'checker8'}
                                align          = {'disable8'}
                                header         = {'none'}
                                value          = {this.state.mappingDigit_8}
                                checker        = {this.state.checker8}
                                disabled       = {this.state.disable8}
                                onChange       = {this.handleValueChanged}
                                maxLength      = {1}
                                tabIndex       = {9}
                                label          = {'8'}/>

                            <TextboxChecker
                                id             = {'mappingDigit_9'}
                                name           = {'checker9'}
                                align          = {'disable9'}
                                header         = {'none'}
                                value          = {this.state.mappingDigit_9}
                                checker        = {this.state.checker9}
                                disabled       = {this.state.disable9}
                                onChange       = {this.handleValueChanged}
                                maxLength      = {1}
                                tabIndex       = {10}
                                label          = {'9'}/>
                            <TextboxChecker
                                id             = {'mappingDigit_P'}
                                name           = {'checker10'}
                                align          = {'disable10'}
                                header         = {'none'}
                                value          = {this.state.mappingDigit_P}
                                checker        = {this.state.checker10}
                                disabled       = {this.state.disable10}
                                onChange       = {this.handleValueChanged}
                                maxLength      = {1}
                                tabIndex       = {11}
                                label          = {'.'}/>

                        </div>
                        <div className="cost-mapping-number-box-wrapper">
                            <div className= "cost-mapping-number-box-container">
                                <div className="cost-mapping-number-box-border">
                                    <div className="dx-fieldset-text">
                                        <div className="dx-field">
                                            <div className="dx-field-label">{formatMessage('Leading')}</div>
                                        </div>
                                    </div>
                                    <div  className="dx-fieldset">
                                        <div className="dx-field">
                                            <div className="dx-field-value">
                                                <NumberBox
                                                    id                = "cost-mapping-number-box"
                                                    className         = "filter-drop-down"
                                                    stylingMode       = "outlined"
                                                    max               = {5}
                                                    min               = {0}
                                                    tabIndex          = {11}
                                                    onValueChanged    = {this.handleDigitChanged}
                                                    value             = {this.state.leadingDigit}
                                                    showSpinButtons   = {true}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Item>
            </Form>
        </>
        )
    }
}

export default CostMapping;